var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, ElementRef, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GoogleChartsLoaderService } from '../google-charts-loader.service';
import { ChartHTMLTooltip } from './chart-html-tooltip';
var GoogleChartComponent = /** @class */ (function () {
    function GoogleChartComponent(el, loaderService) {
        var _this = this;
        this.mouseOverListener = function (item) {
            var event = _this.parseMouseEvent(item);
            event.tooltip = _this.getHTMLTooltip();
            return event;
        };
        this.mouseOutListener = function (item) {
            var event = _this.parseMouseEvent(item);
            return event;
        };
        this.selectListener = function () {
            var event = {
                message: 'select',
                row: null,
                column: null,
                selectedRowValues: [],
                selectedRowFormattedValues: [],
                columnLabel: ''
            };
            var s = _this.wrapper.visualization.getSelection();
            var gs = s[s.length - 1];
            if (!gs) {
                event.message = 'deselect';
                return event;
            }
            var selection = gs;
            if (gs.row != null) {
                event.row = selection.row;
                var selectedRowValues = [];
                var selectedRowFormattedValues = [];
                var dataTable = _this.wrapper.getDataTable();
                var numberOfColumns = dataTable.getNumberOfColumns();
                for (var i = 0; i < numberOfColumns; i++) {
                    selectedRowValues.push(dataTable.getValue(selection.row, i));
                    selectedRowFormattedValues.push(dataTable.getFormattedValue(selection.row, i));
                }
                event.selectedRowValues = selectedRowValues;
                event.selectedRowFormattedValues = selectedRowFormattedValues;
            }
            if (selection.column != null) {
                event.column = selection.column;
                event.columnLabel = _this.getColumnLabelAtPosition(selection);
            }
            if (gs.name) {
                event.columnLabel = gs.name;
            }
            return event;
        };
        this.el = el;
        this.loaderService = loaderService;
        this.chartSelect = new EventEmitter();
        this.chartSelectOneTime = new EventEmitter();
        this.chartReady = new EventEmitter();
        this.chartReadyOneTime = new EventEmitter();
        this.chartError = new EventEmitter();
        this.chartErrorOneTime = new EventEmitter();
        this.mouseOver = new EventEmitter();
        this.mouseOverOneTime = new EventEmitter();
        this.mouseOut = new EventEmitter();
        this.mouseOutOneTime = new EventEmitter();
        this.regionClick = new EventEmitter();
        this.regionClickOneTime = new EventEmitter();
    }
    GoogleChartComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var key = 'data';
        if (changes[key]) {
            if (!this.data) {
                return;
            }
            this.options = this.data.options;
            if (!this.options) {
                this.options = {};
            }
            this.data.component = this;
            this.loaderService.load().then(function () {
                if (_this.wrapper === undefined || _this.wrapper.getChartType() !== _this.data.chartType) {
                    _this.convertOptions();
                    if (_this.data.firstRowIsData && Array.isArray(_this.data.dataTable)) {
                        _this.data.dataTable = google.visualization.arrayToDataTable(_this.data.dataTable, true);
                    }
                    _this.wrapper = new google.visualization.ChartWrapper(_this.data);
                    _this.registerChartWrapperEvents();
                }
                else {
                    // this.unregisterEvents();
                }
                _this.draw();
            });
        }
    };
    GoogleChartComponent.prototype.draw = function () {
        var _this = this;
        if (this.data.dataTable) {
            this.wrapper.setDataTable(this.data.dataTable);
            this._draw();
        }
        else if (this.data.dataSourceUrl) {
            var query = new google.visualization.Query(this.data.dataSourceUrl);
            if (this.data.refreshInterval) {
                query.setRefreshInterval(this.data.refreshInterval);
            }
            if (this.data.query) {
                query.setQuery(this.data.query);
            }
            query.send(function (queryResponse) {
                if (queryResponse.isError()) {
                    return;
                }
                var dt = queryResponse.getDataTable();
                _this.wrapper.setDataTable(dt);
                _this.data.dataTable = dt;
                _this._draw();
            });
        }
    };
    GoogleChartComponent.prototype._draw = function () {
        this.convertOptions();
        this.wrapper.setOptions(this.options);
        this.reformat();
        this.wrapper.draw(this.el.nativeElement.querySelector('div'));
    };
    /**
     * Applies formatters to data columns, if defined
     */
    GoogleChartComponent.prototype.reformat = function () {
        if (!this.data) {
            return;
        }
        if (this.data.formatters !== undefined) {
            var dt = this.wrapper.getDataTable();
            for (var _i = 0, _a = this.data.formatters; _i < _a.length; _i++) {
                var formatterConfig = _a[_i];
                var formatter = void 0;
                if (formatterConfig.type === 'PatternFormat') {
                    var _fmtOptions = formatterConfig.options;
                    formatter = new google.visualization.PatternFormat(_fmtOptions.pattern);
                    formatter.format(dt, formatterConfig.columns, _fmtOptions.dstColumnIndex);
                    continue;
                }
                var formatterConstructor = google.visualization[formatterConfig.type];
                var formatterOptions = formatterConfig.options;
                formatter = new formatterConstructor(formatterOptions);
                if (formatterConfig.type === 'ColorFormat' && formatterOptions) {
                    var _fmtOptions = formatterOptions;
                    for (var _b = 0, _c = _fmtOptions.ranges; _b < _c.length; _b++) {
                        var range = _c[_b];
                        if (typeof (range.fromBgColor) !== 'undefined' && typeof (range.toBgColor) !== 'undefined') {
                            formatter.addGradientRange(range.from, range.to, range.color, range.fromBgColor, range.toBgColor);
                        }
                        else {
                            formatter.addRange(range.from, range.to, range.color, range.bgcolor);
                        }
                    }
                }
                for (var _d = 0, _e = formatterConfig.columns; _d < _e.length; _d++) {
                    var col = _e[_d];
                    formatter.format(dt, col);
                }
            }
        }
    };
    GoogleChartComponent.prototype.getSelectorBySeriesType = function (seriesType) {
        var selectors = {
            bars: 'bar#%s#%r',
            haxis: 'hAxis#0#label',
            line: 'point#%s#%r',
            legend: 'legendentry#%s',
            area: 'point#%s#%r'
        };
        var selector = selectors[seriesType];
        return selector;
    };
    /**
     * Given a column number, counts how many
     * columns have rol=="data". Those are mapped
     * one-to-one to the series array. When rol is not defined
     * a column of type number means a series column.
     * @param column to inspect
     */
    GoogleChartComponent.prototype.getSeriesByColumn = function (column) {
        var series = 0;
        var dataTable = this.wrapper.getDataTable();
        for (var i = column - 1; i >= 0; i--) {
            var role = dataTable.getColumnRole(i);
            var type = dataTable.getColumnType(i);
            if (role === 'data' || type === 'number') {
                series++;
            }
        }
        return series;
    };
    GoogleChartComponent.prototype.getBoundingBoxForItem = function (item) {
        var boundingBox = { top: 0, left: 0, width: 0, height: 0 };
        if (this.cli) {
            var column = item.column;
            var series = this.getSeriesByColumn(column);
            var row = item.row;
            var seriesType = this.options.seriesType;
            if (this.options.series && this.options.series[series] && this.options.series[series].type) {
                seriesType = this.options.series[series].type;
            }
            if (seriesType) {
                var selector = this.getSelectorBySeriesType(seriesType);
                if (selector) {
                    selector = selector.replace('%s', series + '').replace('%c', column + '').replace('%r', row + '');
                    var box = this.cli.getBoundingBox(selector);
                    if (box) {
                        boundingBox = box;
                    }
                }
            }
        }
        return boundingBox;
    };
    GoogleChartComponent.prototype.getValueAtPosition = function (position) {
        if (position.row == null) {
            return null;
        }
        var dataTable = this.wrapper.getDataTable();
        var value = dataTable.getValue(position.row, position.column);
        return value;
    };
    GoogleChartComponent.prototype.getColumnTypeAtPosition = function (position) {
        var dataTable = this.wrapper.getDataTable();
        var type = dataTable.getColumnType(position.column) || '';
        return type;
    };
    GoogleChartComponent.prototype.getColumnLabelAtPosition = function (position) {
        var dataTable = this.wrapper.getDataTable();
        var type = dataTable.getColumnLabel(position.column) || '';
        return type;
    };
    GoogleChartComponent.prototype.getHTMLTooltip = function () {
        var tooltipER = new ElementRef(this.el.nativeElement.querySelector('.google-visualization-tooltip'));
        return new ChartHTMLTooltip(tooltipER);
    };
    GoogleChartComponent.prototype.parseMouseEvent = function (item) {
        var chartType = this.wrapper.getChartType();
        var eventColumn = item.column;
        if (eventColumn == null) {
            switch (chartType) {
                case 'Timeline':
                    eventColumn = this.wrapper.getDataTable().getNumberOfColumns() === 3 ? 0 : 1;
                    break;
                default:
                    eventColumn = 0;
            }
        }
        var eventRow = item.row;
        var myItem = {
            row: eventRow,
            column: eventColumn
        };
        var event = {
            position: item,
            boundingBox: this.getBoundingBoxForItem(myItem),
            value: this.getValueAtPosition(myItem),
            columnType: this.getColumnTypeAtPosition(myItem),
            columnLabel: this.getColumnLabelAtPosition(myItem)
        };
        return event;
    };
    GoogleChartComponent.prototype.unregisterEvents = function () {
        google.visualization.events.removeAllListeners(this.wrapper.getChart());
        google.visualization.events.removeAllListeners(this.wrapper);
    };
    GoogleChartComponent.prototype.registerChartEvents = function () {
        var _this = this;
        var chart = this.wrapper.getChart();
        this.cli = chart.getChartLayoutInterface ? chart.getChartLayoutInterface() : null;
        if (this.mouseOver.observers.length > 0) {
            google.visualization.events.addListener(chart, 'onmouseover', function (item) {
                var event = _this.parseMouseEvent(item);
                event.tooltip = _this.getHTMLTooltip();
                _this.mouseOver.emit(event);
            });
        }
        if (this.mouseOverOneTime.observers.length > 0) {
            google.visualization.events.addOneTimeListener(chart, 'onmouseover', function (item) {
                var event = _this.parseMouseEvent(item);
                event.tooltip = _this.getHTMLTooltip();
                _this.mouseOverOneTime.emit(event);
            });
        }
        if (this.mouseOut.observers.length > 0) {
            google.visualization.events.addListener(chart, 'onmouseout', function (item) {
                var event = _this.parseMouseEvent(item);
                _this.mouseOut.emit(event);
            });
        }
        if (this.mouseOutOneTime.observers.length > 0) {
            google.visualization.events.addOneTimeListener(chart, 'onmouseout', function (item) {
                var event = _this.parseMouseEvent(item);
                _this.mouseOutOneTime.emit(event);
            });
        }
        if (this.data.chartType === 'GeoChart') {
            if (this.regionClick.observers.length > 0) {
                google.visualization.events.addListener(chart, 'regionClick', function (item) {
                    _this.regionClick.emit(item);
                });
            }
            if (this.regionClickOneTime.observers.length > 0) {
                google.visualization.events.addOneTimeListener(chart, 'regionClick', function (item) {
                    _this.regionClick.emit(item);
                });
            }
        }
    };
    GoogleChartComponent.prototype.registerChartWrapperEvents = function () {
        var _this = this;
        google.visualization.events.addListener(this.wrapper, 'ready', function () {
            _this.chartReady.emit({ message: 'Chart ready' });
        });
        google.visualization.events.addOneTimeListener(this.wrapper, 'ready', function () {
            _this.chartReadyOneTime.emit({ message: 'Chart ready (one time)' });
            _this.registerChartEvents();
        });
        google.visualization.events.addListener(this.wrapper, 'error', function (error) {
            _this.chartError.emit(error);
        });
        google.visualization.events.addOneTimeListener(this.wrapper, 'error', function (error) {
            _this.chartErrorOneTime.emit(error);
        });
        this.addListener(this.wrapper, 'select', this.selectListener, this.chartSelect);
        this.addOneTimeListener(this.wrapper, 'select', this.selectListener, this.chartSelectOneTime);
    };
    GoogleChartComponent.prototype.addListener = function (source, eventType, listenerFn, evEmitter) {
        google.visualization.events.addListener(source, eventType, function () {
            evEmitter.emit(listenerFn());
        });
    };
    GoogleChartComponent.prototype.addOneTimeListener = function (source, eventType, listenerFn, evEmitter) {
        google.visualization.events.addOneTimeListener(source, eventType, function () {
            evEmitter.emit(listenerFn());
        });
    };
    GoogleChartComponent.prototype.convertOptions = function () {
        try {
            this.options = google.charts[this.data.chartType].convertOptions(this.options);
        }
        catch (error) {
            return;
        }
    };
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], GoogleChartComponent.prototype, "data", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartReady", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartReadyOneTime", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartError", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartErrorOneTime", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartSelect", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "chartSelectOneTime", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "mouseOver", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "mouseOverOneTime", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "mouseOut", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "mouseOutOneTime", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "regionClick", void 0);
    __decorate([
        Output(),
        __metadata("design:type", EventEmitter)
    ], GoogleChartComponent.prototype, "regionClickOneTime", void 0);
    GoogleChartComponent = __decorate([
        Component({
            selector: 'google-chart',
            template: '<div></div>',
            changeDetection: ChangeDetectionStrategy.OnPush
        }),
        __metadata("design:paramtypes", [ElementRef,
            GoogleChartsLoaderService])
    ], GoogleChartComponent);
    return GoogleChartComponent;
}());
export { GoogleChartComponent };
